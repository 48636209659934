<template>
    <button v-if="musicData.audio" class="gzPlayButton @all-unset" :aria-label="songText" @click.prevent.stop="toggle">
        <div v-if="!$slots.button" class="playIconsWrapper">
            <div class="universalButton" :class="{paused: !musicData.isPlaying}">
                <div :class="{pause: musicData.isPlaying}"/>
            </div>
            <div v-if="musicData.isPlaying" class="musicBar">
                <div>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                </div>
            </div>
        </div>
        <slot v-else name="button" :is-playing="musicData.isPlaying"/>
    </button>
</template>

<script>
    export default {
        name: "PlayButton",
        props: {
            data: {
                type: Object,
                required: true
            },
            showPlayer: {
                type: Boolean,
                default: true
            }
        },
        emits: ["sf-player-paused", "sf-player-started"],
        data() {
            return {
                musicData: null
            };
        },
        computed: {
            songText() {
                return `Play ${this.musicData?.song_title} by ${this.musicData?.name}`;
            }
        },
        watch: {
            data(val) {
                this.clearNext();
                this.initData(val);
            }
        },
        created() {
            this.initData(this.data);
        },
        beforeUnmount() {
            this.clearNext();
        },
        methods: {
            initData(data){
                this.musicData = data;
                if (this.musicData.audio) {
                    this.$sfPlayer.addToPlayList(data);
                }
            },
            clearNext() {
                this.musicData.removeNext = true;
                this.$sfPlayer.clearNext();
            },
            toggle() {
                //If we don't have an audio file, then don't try to play the song
                if (!this.musicData.audio) return;

                if (this.$sfPlayer.hidePlayerState && this.showPlayer) {
                    this.$sfPlayer.removeMinify();
                    this.$sfPlayer.hidePlayer(false);
                }

                let index = this.$sfPlayer.findMusicDataIndex(this.musicData);
                if (index === -1) {
                    index = this.$sfPlayer.addToPlayList(this.musicData);
                }
                this.$sfPlayer.setCurrentPlaylistIndex(index);

                //Depending on current music data state, pause or start the player
                if (this.musicData.isPlaying) {
                    this.$sfPlayer.pause();
                    this.$emit("sf-player-paused");
                } else {
                    this.$sfPlayer.play();
                    this.$emit("sf-player-started");
                }

                return true;
            }
        },
    };
</script>

<style lang="scss">
    .gzPlayButton {
        filter: drop-shadow(rgba(0, 0, 0, 0.15) 0px 0px 20px);
        width: 48px;
        height: 48px;
        cursor: pointer;
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }

        .musicBar {
            width: 50px;
            height: 50px;
            background-color: #ffffff;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: -1px;
            top: -1px;

            > div {
                position: relative;
                display: flex;
                justify-content: space-between;
                width: 15px;
                height: 15px;

                span {
                    width: 3px;
                    height: 100%;
                    background-color: #262420;
                    transform-origin: bottom;
                    animation: bounce 2.2s ease infinite alternate;
                    content: '';

                    &:nth-of-type(2) {
                        animation-delay: -2.2s;
                    }

                    &:nth-of-type(1) {
                        animation-delay: -3.7s;
                    }

                    &:nth-of-type(4) {
                        animation-delay: -1.6s;
                    }
                }
            }
        }

        &.bigButton {
            width: 60px;
            height: 60px;
            filter: none;

            .musicBar {
                width: 62px;
                height: 62px;
                left: -1px;
                top: -1px;

                > div {
                    width: 20px;
                    height: 20px;

                    span {
                        width: 4px;
                        height: 100%;
                    }
                }
            }
        }

        &.invertColor {
            .musicBar {
                background-color: var(--cherry);

                > div {
                    span {
                        background-color: #ffffff;
                    }
                }
            }
        }
    }

    .gzPlayButton {
        &.bigButton {
            .universalButton {
                width: 60px;
                height: 60px;

                div {
                    height: 22px;
                    border-width: 12px 0 12px 16px;
                    margin-left: 6px;

                    &.pause {
                        border-width: 0 0 0 16px;
                        height: 20px;
                        margin-left: 0;
                    }
                }
            }
        }

        &.invertColor {
            .playIconsWrapper {
                .universalButton {
                    background-color: #ffffff;

                    div {
                        border-color: transparent transparent transparent #262420;
                    }

                    &.paused {
                        background-color: var(--cherry);

                        div {
                            border-color: transparent transparent transparent #ffffff;
                        }

                        &:hover {
                            background-color: #ffffff;

                            div {
                                border-color: transparent transparent transparent #262420;
                            }
                        }
                    }
                }
            }
        }
    }

    .universalButton {
        width: 48px;
        height: 48px;
        background-color: var(--cherry);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.2s;

        div {
            box-sizing: border-box;
            height: 15px;

            border-color: transparent transparent transparent #ffffff;
            transition: 100ms all ease;
            will-change: border-width;
            cursor: pointer;

            // play state
            border-style: solid;
            border-width: 9px 0 9px 13px;
            margin-left: 3px;
            margin-bottom: 1px;
            // paused state
            &.pause {
                border-style: double;
                border-width: 0 0 0 13px;
                margin: 0;
                height: 16px;
            }
        }

        &.paused {
            background-color: #ffffff;

            > div {
                border-color: transparent transparent transparent #262420;
            }
        }
    }

    .universalButton:hover, .frameBox:hover .universalButton {
        &.paused {
            background-color: var(--cherry);

            > div {
                border-color: transparent transparent transparent #ffffff;
            }
        }
    }

    .playIconsWrapper:hover, .frameBox:hover {
        .musicBar {
            opacity: 0;
        }
    }

    @media (hover: none) {
        .playIconsWrapper:hover, .frameBox:hover {
            .musicBar {
                opacity: 1;
            }
        }
    }

    @keyframes bounce {
        10% {
            transform: scaleY(0.3);
        }

        30% {
            transform: scaleY(1);
        }

        60% {
            transform: scaleY(0.5);
        }

        80% {
            transform: scaleY(0.75);
        }

        100% {
            transform: scaleY(0.6);
        }
    }
</style>

<style>
    .gzPlayButton {
        .musicBar {
            background-color: var(--sfc-white);

            > div {
                span {
                    background-color: var(--sfc-black);
                }
            }
        }

        &.invertColor {
            .musicBar {
                background-color: var(--cherry);

                > div {
                    span {
                        background-color: var(--sfc-white);
                    }
                }
            }
        }
    }

    .gzPlayButton {
        &.invertColor {
            .playIconsWrapper {
                .universalButton {
                    background-color: var(--sfc-white);

                    div {
                        border-color: transparent transparent transparent var(--sfc-black);
                    }

                    &.paused {
                        background-color: var(--cherry);

                        div {
                            border-color: transparent transparent transparent var(--sfc-white);
                        }
                        @media (hover: hover) and (pointer: fine) {
                            &:hover {
                                background-color: var(--sfc-white);

                                div {
                                    border-color: transparent transparent transparent var(--sfc-black);
                                }
                            }
                        }
                    }
                }
            }
        }

        &.blackButton {
            .playIconsWrapper {
                .universalButton {
                    background-color: #262420;

                    div {
                        border-color: transparent transparent transparent #ffffff;
                    }

                    &.paused {
                        background-color: #262420;

                        div {
                            border-color: transparent transparent transparent #ffffff;
                        }
                        @media (hover: hover) and (pointer: fine) {
                            &:hover {
                                background-color: var(--cherry);

                                div {
                                    border-color: transparent transparent transparent #ffffff;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.mkPlayButton {
            position: relative;
            height: 30px;

            .musicBar {
                color: var(--sfc-white);
                background: var(--cherry);
                border: 0;
                box-shadow: none;
                transition: all .3s;
                border-radius: 35px;
                text-align: center !important;
                cursor: pointer;
                outline: none;

                display: flex;
                height: 30px;
                min-width: 20px;
                justify-content: center;
                align-items: center;
                position: absolute;
                left: 0;
                top: 0;
                background-color: var(--sfc-dark-beige);
                box-shadow: none;
                padding: 14.5px 15px;

                &:hover {
                    background: var(--sfc-dark-beige);
                }
            }

            .playIconsWrapper {
                position: relative;

                .universalButton {    color: var(--sfc-white);
                    background: var(--cherry);
                    border: 0;
                    box-shadow: none;
                    padding: 15px 25px;
                    transition: all .3s;
                    border-radius: 35px;
                    text-align: center !important;
                    cursor: pointer;
                    outline: none;
                    position: relative;
                    white-space: nowrap;

                    display: flex;
                    height: 15px;
                    min-width: 20px;
                    justify-content: center;
                    align-items: center;
                    background-color: var(--sfc-dark-beige);

                    div {
                        height: 15px;
                        margin: 0 0 0 4px;

                        &.pause {
                            border-color: transparent transparent transparent var(--sfc-black);
                            margin: 0;
                        }
                    }
                    @media (hover: hover) and (pointer: fine) {
                        &:hover {
                            background-color: var(--sfc-dark-beige);

                            &.paused {
                                > div {
                                    @screen tablet-mobile {
                                        border-color: transparent transparent transparent var(--sfc-black);
                                    }
                                }
                            }
                        }
                    }
                    &.paused {
                        div {
                            height: 10px;
                            border-width: 6px 0 6px 10px;
                        }
                        @media (hover: hover) and (pointer: fine) {
                            &:hover {
                                background-color: var(--sfc-black);

                                @screen tablet-mobile {
                                    background-color: var(--sfc-dark-beige);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .universalButton {
        background-color: var(--cherry);

        div {
            border-color: transparent transparent transparent var(--sfc-white);
        }

        &.paused {
            background-color: var(--sfc-white);

            > div {
                border-color: transparent transparent transparent var(--sfc-black);
            }
        }
    }

    @media (hover: hover) and (pointer: fine) {
        .universalButton:hover, .frameBox:hover .universalButton {
            &.paused {
                background-color: var(--cherry);

                > div {
                    border-color: transparent transparent transparent var(--sfc-white);
                }
            }
        }
    }
</style>
